// TODO content of "file.html" should be manually set here, see comments in "file.html"

export default '<html prefix="ddo: http://onto.fel.cvut.cz/ontologies/application/termit/pojem/">\n' +
  "<head></head>\n" +
  "<body>\n" +
  '<div class="cast" id="tcz_cast_prvni">\n' +
  "    <h1>Část první:\n" +
  "\n" +
  "        <br>Úvodní ustanovení\n" +
  "\n" +
  "    </h1>\n" +
  '    <div class="hlava" id="tcz_cast_prvni_hlava_I">\n' +
  "        <h2>01/ Hlava I\n" +
  "\n" +
  '            <span about="_:1716840807" property="ddo:je-vyskytem-termu"\n' +
  '                  resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                  typeof="ddo:výskyt-termu" score="0.9">(</span>II\n' +
  "\n" +
  '            <span about="_:1895323707" property="ddo:je-vyskytem-termu"\n' +
  '                  resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                  typeof="ddo:výskyt-termu" score="0.8">)</span>\n' +
  "            <br>Smysl a účel plánování Prahy\n" +
  "\n" +
  "        </h2>\n" +
  '        <div class="clanek" id="tcz_cl_1">\n' +
  "            <h4>Článek 1\n" +
  "\n" +
  '                <br><span about="_:2126723403" property="ddo:je-vyskytem-termu" resource="http://data.iprpraha.cz/zdroj/slovnik/metropolitan-plan" typeof="ddo:výskyt-termu" score="0.7">Metropolitní plán</span>\n' +
  "\n" +
  "            </h4>\n" +
  '            <div class="odstavec" id="tcz_cl_1_odst_1">\n' +
  "                <p>\n" +
  '                    <span about="_:503864250" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu" score="0.6">(</span>1\n' +
  "\n" +
  '                    <span about="_:464152225" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu" score="0.5">) </span>Metropolitní plán jako Územní plán hlavního\n' +
  "\n" +
  '                    <span about="_:694019650" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/mesto" typeof="ddo:výskyt-termu"  score="1.0">města </span>Prahy\n' +
  "\n" +
  '                    <span about="_:1946889818" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>dále též hl. m. Prahy\n' +
  "\n" +
  '                    <span about="_:671248106" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/struktura-%28krajiny%2C-mesta%29"\n' +
  '                          typeof="ddo:výskyt-termu">, </span>nebo Prahy\n' +
  "\n" +
  "                    <sup>\n" +
  '                        <a href="#tcz-footnote-2" id="tcz-footnote-ref-2">[1]</a>\n' +
  "                    </sup>\n" +
  '                    <span about="_:187206896" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>tvoří základ uceleného\n' +
  "\n" +
  '                    <span about="_:812045889" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/struktura-%28krajiny%2C-mesta%29"\n' +
  '                          typeof="ddo:výskyt-termu">, </span>logického a vzájemně provázaného systému územně plánovací\n' +
  "                    dokumentace\n" +
  "\n" +
  '                    <span about="_:2091808474" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/mesto" typeof="ddo:výskyt-termu">města</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_1_odst_2">\n' +
  "                <p>\n" +
  '                    <span about="_:1969814066" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>2\n' +
  "\n" +
  '                    <span about="_:1933245952" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán je pořízen pro\n' +
  "\n" +
  '                    <span about="_:55503675" property="ddo:je-vyskytem-termu" resource="" typeof="ddo:výskyt-termu">stanovení koncepce </span>celého\n' +
  "                    správního\n" +
  "\n" +
  '                    <span about="_:835008522" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu"  score="0.7">území </span>Prahy\n' +
  "\n" +
  '                    <span about="_:355142348" property="ddo:je-vyskytem-termu"\n' +
  '                          resource=""\n' +
  '                          typeof="ddo:výskyt-termu" score="0.7">, </span>a to v měřítku 1:10.000 odpovídajícím rozsahu řešeného\n' +
  "\n" +
  '                    <span about="_:56644596" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-6"\n' +
  '                          typeof="ddo:výskyt-termu">území</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_1_odst_3">\n' +
  "                <p>\n" +
  '                    <span about="_:1864242194" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>3\n' +
  "\n" +
  '                    <span about="_:573447767" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán vytváří podmínky pro účelnou správu a\n' +
  "\n" +
  '                    <span about="_:1334041096" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu">rozvoj území </span>a stanovuje základní rámce pro všechny\n' +
  "                    navazující podrobnější úrovně plánování.\n" +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_1_odst_4">\n' +
  "                <p>\n" +
  '                    <span about="_:36477339" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>4\n' +
  "\n" +
  '                    <span about="_:569333605" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-3"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán je koncepčním\n' +
  "\n" +
  '                    <span about="_:1886227146" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/dokument" typeof="ddo:výskyt-termu">dokumentem</span>.Prostřednictvím\n' +
  "                    stanovených regulací umožňuje rozhodovat ve správních řízeních.\n" +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  "        </div>\n" +
  '        <div class="clanek" id="tcz_cl_2">\n' +
  "            <h4>\n" +
  '                <span about="_:1371886354" property="ddo:je-vyskytem-termu" resource=""\n' +
  '                      typeof="ddo:výskyt-termu">Článek </span>2\n' +
  "\n" +
  "                <br>Strukturální plán\n" +
  "\n" +
  "            </h4>\n" +
  '            <div class="odstavec" id="tcz_cl_2_odst_1">\n' +
  "                <p>\n" +
  '                    <span about="_:1051133536" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>1\n' +
  "\n" +
  '                    <span about="_:1157730149" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán je koncipován především jako plán\n' +
  "\n" +
  '                    <span about="_:1711769908" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu">struktury území </span>a jeho rozpoznatelného a zapamatovatelného\n' +
  "                    obrazu\n" +
  "\n" +
  '                    <span about="_:1611995026" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/struktura-%28krajiny%2C-mesta%29"\n' +
  '                          typeof="ddo:výskyt-termu">, </span>se\n' +
  "\n" +
  '                    <span about="_:690411090" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/plocha-rozdilneho-vyuziti-pro-stanoveni-vyskove-regulace"\n' +
  '                          typeof="ddo:výskyt-termu">stanovením </span>z toho plynoucích podmínek\n' +
  "\n" +
  '                    <span about="_:1992606816" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu" score="0.7">využití území</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_2_odst_2">\n' +
  "                <p>\n" +
  '                    <span about="_:556675453" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>2\n' +
  "\n" +
  '                    <span about="_:140565553" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Strukturální pojetí\n' +
  "\n" +
  '                    <span about="_:1482161817" property="ddo:je-vyskytem-termu" resource="" typeof="ddo:výskyt-termu">plánu </span>rozvíjí\n' +
  "                    a doplňuje\n" +
  "\n" +
  '                    <span about="_:1229388194" property="ddo:je-vyskytem-termu" resource="" typeof="ddo:výskyt-termu" score="0.8">koncepci </span>Pražských\n' +
  "\n" +
  '                    <span about="_:431197230" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/stavebni-blok"\n' +
  '                          typeof="ddo:výskyt-termu">stavebních </span>předpisů a standardního urbanistického\n' +
  "                    pojmosloví.Důraz klade na vztahy\n" +
  "\n" +
  '                    <span about="_:389831293" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">zástavby </span>a\n' +
  "\n" +
  '                    <span about="_:2118173398" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu" score="0.7">veřejných prostranství, zástavby </span>a\n' +
  "\n" +
  '                    <span about="_:1214724430" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/transtormacni-plocha-otevrene-krajiny"\n' +
  '                          typeof="ddo:výskyt-termu">krajiny </span>ve městě i\n' +
  "\n" +
  '                    <span about="_:1953803654" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/transtormacni-plocha-otevrene-krajiny"\n' +
  '                          typeof="ddo:výskyt-termu">otevřené krajiny</span>.\n' +
  "\n" +
  '                    <span about="_:207263660" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/test-vocabulary/term/pojem-4"\n' +
  '                          typeof="ddo:výskyt-termu" score="0.7">Struktura </span>je zároveň hlavním určujícím\n' +
  "\n" +
  '                    <span about="_:2097570336" property="ddo:je-vyskytem-termu"\n' +
  '                          resource=""\n' +
  '                          typeof="ddo:výskyt-termu" score="0.8">prvkem </span>pro uspořádání jednotlivých systémů a subsystémů\n' +
  "\n" +
  '                    <span about="_:1120390357" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/infrastruktura"\n' +
  '                          typeof="ddo:výskyt-termu">infrastruktury</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_2_odst_3">\n' +
  "                <p>\n" +
  '                    <span about="_:1261523900" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>3\n' +
  "\n" +
  '                    <span about="_:90718104" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán stabilizuje\n' +
  "\n" +
  '                    <span about="_:416679637" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/mesto" typeof="ddo:výskyt-termu">charakter města</span>.Chrání\n' +
  "                    jeho kulturní a civilizační hodnoty\n" +
  "\n" +
  '                    <span about="_:1497041600" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/struktura-%28krajiny%2C-mesta%29"\n' +
  '                          typeof="ddo:výskyt-termu">, </span>stejně jako hodnoty\n' +
  "\n" +
  '                    <span about="_:493582976" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/nezastavitelne-prirodni-lokality-a-plochy"\n' +
  '                          typeof="ddo:výskyt-termu">přírodní</span>.Zároveň definuje potenciál\n' +
  "\n" +
  '                    <span about="_:1272762979" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/mesto" typeof="ddo:výskyt-termu">města </span>k\n' +
  "\n" +
  '                    <span about="_:1652096715" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">rozvoji zástavby </span>a celkového kompozičního uspořádání.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  "        </div>\n" +
  '        <div class="clanek" id="tcz_cl_3">\n' +
  "            <h4>\n" +
  '                <span about="_:165918279" property="ddo:je-vyskytem-termu" resource=""\n' +
  '                      typeof="ddo:výskyt-termu">Článek </span>3\n' +
  "\n" +
  "                <br>Digitální plán\n" +
  "\n" +
  "            </h4>\n" +
  '            <div class="odstavec" id="tcz_cl_3_odst_1">\n' +
  "                <p>\n" +
  '                    <span about="_:1176978676" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>1\n' +
  "\n" +
  '                    <span about="_:1390580729" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán je zpracován elektronicky a veškeré\n' +
  "                    informace i\n" +
  "\n" +
  '                    <span about="_:1145246477" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/regulace"\n' +
  '                          typeof="ddo:výskyt-termu">regulace </span>v něm obsažené jsou\n' +
  "\n" +
  '                    <span about="_:783840850" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>souběžně s tištěnou autorizovanou verzí\n' +
  "\n" +
  '                    <span about="_:1388611477" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>obsaženy v datovém\n' +
  "\n" +
  '                    <span about="_:343915375" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/model"\n' +
  '                          typeof="ddo:výskyt-termu">modelu </span>tak\n' +
  "\n" +
  '                    <span about="_:1215830422" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/struktura-%28krajiny%2C-mesta%29"\n' +
  '                          typeof="ddo:výskyt-termu">, </span>aby byly kdykoli v souhrnu a synergicky využitelné pro\n' +
  "                    rozhodování o\n" +
  "\n" +
  '                    <span about="_:622849069" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/vyuziti-uzemi"\n' +
  '                          typeof="ddo:výskyt-termu">využití </span>a uspořádání\n' +
  "\n" +
  '                    <span about="_:1393635124" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/vyuziti-uzemi"\n' +
  '                          typeof="ddo:výskyt-termu">území </span>a o změnách v\n' +
  "\n" +
  '                    <span about="_:979124986" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/vyuziti-uzemi"\n' +
  '                          typeof="ddo:výskyt-termu">území</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_3_odst_2">\n' +
  "                <p>\n" +
  '                    <span about="_:1757717627" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>2\n' +
  "\n" +
  '                    <span about="_:846618328" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Metropolitní plán je především strukturovaným systémem dat\n' +
  "\n" +
  '                    <span about="_:1785037310" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>a informací\n' +
  "\n" +
  '                    <span about="_:1517359108" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>o\n' +
  "\n" +
  '                    <span about="_:391876685" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/vyuziti-uzemi"\n' +
  '                          typeof="ddo:výskyt-termu">území</span>.Data Metropolitního plánu v kombinaci s daty průběžně\n' +
  "                    aktualizovaného systému dat o stavu a limitech\n" +
  "\n" +
  '                    <span about="_:1366787460" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">území (</span>územně analytické podklady\n' +
  "\n" +
  '                    <span about="_:64249502" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>jsou podkladem pro navrhování a vytváření aplikací anebo\n' +
  "                    informačních systémů na míru potřeb jednotlivých uživatelů.\n" +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_3_odst_3">\n' +
  "                <p>\n" +
  '                    <span about="_:61986341" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>3\n' +
  "\n" +
  '                    <span about="_:1854351474" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Datový\n' +
  "\n" +
  '                    <span about="_:1334225810" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/model"\n' +
  '                          typeof="ddo:výskyt-termu">model </span>je také podkladem a součástí vyhodnocování naplňování\n' +
  "                    Metropolitního\n" +
  "\n" +
  '                    <span about="_:876739028" property="ddo:je-vyskytem-termu" resource="" typeof="ddo:výskyt-termu">plánu </span>a\n' +
  "                    pro vyhodnocení vlivů na udržitelný\n" +
  "\n" +
  '                    <span about="_:1851746932" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/vyuziti-uzemi"\n' +
  '                          typeof="ddo:výskyt-termu">rozvoj území</span>.\n' +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  '            <div class="odstavec" id="tcz_cl_3_odst_4">\n' +
  "                <p>\n" +
  '                    <span about="_:793465348" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">(</span>4\n' +
  "\n" +
  '                    <span about="_:1985134163" property="ddo:je-vyskytem-termu"\n' +
  '                          resource="http://data.iprpraha.cz/zdroj/slovnik/mpp-3/pojem/modernisticka-struktura-%28zastavba%29"\n' +
  '                          typeof="ddo:výskyt-termu">) </span>Digitálně je Metropolitní plán vydán ve formě… a digitálně\n' +
  "                    podepsán…\n" +
  "\n" +
  "                </p>\n" +
  "            </div>\n" +
  "        </div>\n" +
  "    </div>\n" +
  "</div>\n" +
  "</body>\n" +
  "</html>";
